import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {deleteSet, getSets} from '../../actions/sets';
import AddSetModal from './AddSetModal';
import {getWorkout} from '../../actions/workouts';
import {clearMessages} from '../../actions/messages';
import SetsTable from "./SetsTable";
import {Col, Container, Row} from 'reactstrap';

// eslint-disable-next-line no-shadow
const Sets = ({
                workoutID,
                workoutActive,
                sets = [],
                messages,
                getWorkout,
                deleteSet,
                clearMessages,
                currentWorkout,
              }) => {
  useEffect(() => {
    if (messages.addSet || messages.deleteSet) {
      getWorkout(workoutID);
      clearMessages();
    }
  }, [messages]);


  return (
    <>
      <div
        className="pricing-4 section"
        style={{
          position: 'relative',
          overflow: 'hidden',
          padding: 16,
        }}
      >
        <Container style={{
          position: 'relative',
          zIndex: 2,
        }}>
          {currentWorkout ? (
              <Row>
                <Col className="ml-auto mr-auto text-center" md="6">
                  <h2 className="title">{currentWorkout.name}</h2>
                </Col>
              </Row>
            ) :
            false
          }
          <Row>
            <Col md="4">
              <div className="info">
                <div className="description-section">
                  <h4 className="info-title">Workout Summary</h4>
                  <ul className="list-unstyled">
                    <li>
                      <span className="font-weight-bold"># of sets:</span>
                      {sets.length}
                    </li>
                    <li>
                      <span className="font-weight-bold">Total Duration:</span>{' '}
                      {sets.reduce((a, b) => {
                        return a + b.duration;
                      }, 0)}
                    </li>
                    <li>
                      <span className="font-weight-bold">Average Duration:</span>{' '}
                      {sets.reduce((a, b) => {
                        return a + b.duration;
                      }, 0) / sets.length}
                    </li>
                  </ul>
                  {workoutID && workoutActive && <AddSetModal/>}
                </div>
              </div>
              <div className="info">
                <div className="description">
                  Hello
                </div>
              </div>
            </Col>
            <Col md="8">
              <div className="info" style={{width: 'fit-content', maxWidth: 'none'}}>
                <div className="description-section">
                  <SetsTable data={sets}/>

                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

Sets.propTypes = {
  getSets: PropTypes.func.isRequired,
  deleteSet: PropTypes.func.isRequired,
  clearMessages: PropTypes.func.isRequired,
  getWorkout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  sets: state.sets.sets,
  messages: state.messages,
});

export default connect(mapStateToProps, {getSets, deleteSet, getWorkout, clearMessages})(Sets);
