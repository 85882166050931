export const ADD_SET = 'ADD_SET';
export const CLEAR_SETS = 'CLEAR_SETS';
export const DELETE_SET = 'DELETE_SET';
export const GET_SET = 'GET_SET';
export const GET_SETS = 'GET_SETS';
export const UPDATE_SET = 'UPDATE_SET';

export const ADD_WORKOUT = 'ADD_WORKOUT';
export const CLEAR_WORKOUTS = 'CLEAR_WORKOUTS';
export const DELETE_WORKOUT = 'DELETE_WORKOUT';
export const GET_WORKOUT = 'GET_WORKOUT';
export const GET_WORKOUTS = 'GET_WORKOUTS';
export const UPDATE_WORKOUT = 'UPDATE_WORKOUT';

export const ADD_EXERCISE = 'ADD_EXERCISE';
export const CLEAR_EXERCISES = 'CLEAR_EXERCISES';
export const DELETE_EXERCISE = 'DELETE_EXERCISE';
export const GET_EXERCISE = 'GET_EXERCISE';
export const GET_EXERCISES = 'GET_EXERCISES';
export const UPDATE_EXERCISE = 'UPDATE_EXERCISE';

export const ADD_PROFILE = 'ADD_PROFILE';
export const CLEAR_PROFILES = 'CLEAR_PROFILES';
export const DELETE_PROFILE = 'DELETE_PROFILE';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';

export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const GET_ERRORS = 'GET_ERRORS';
export const CREATE_MESSAGE = 'CREATE_MESSAGE';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
