import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import SetsList from './SetList';
import { deleteWorkout, getWorkout, updateWorkout } from '../../actions/workouts';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Sets from '../workouts/Sets';

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from 'reactstrap';

const Workout = ({ match, getWorkout, workout }) => {
  const initialWorkoutState = null;
  const [currentWorkout, setCurrentWorkout] = useState(initialWorkoutState);
  const [editWorkout, setEditWorkout] = useState(false);
  const [message, setMessage] = useState('');
  let history = useHistory();

  useEffect(() => {
    getWorkout(match.params.id);
  }, [match.params.id]);

  useEffect(() => {
    if (workout) {
      setCurrentWorkout({ ...currentWorkout, ...workout });
    }
  }, [workout]);

  const updateWorkout = () => {
    // WorkoutDataService.update(currentWorkout.id, currentWorkout)
    //     .then(response => {
    //         console.log(response.data);
    //         setMessage("The workout was updated successfully!");
    //         setEditWorkout(false)
    //     })
    //     .catch(e => {
    //         console.log(e);
    //     });
  };

  const deleteWorkout = () => {
    deleteWorkout();
    history.push('/dashboard');
  };

  const toggleEditWorkout = () => {
    setEditWorkout(true);
    setMessage('');
  };

  return currentWorkout ? (
    <>
      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center" md="12">
            <h2 className="title">{currentWorkout.name}</h2>
          </Col>
        </Row>
      </Container>

      <Sets workoutID={currentWorkout.id} workoutActive={currentWorkout.active} />
    </>
  ) : (
    <div>
      <br />
      <p>Workout failed to load.</p>
    </div>
  );
};

Workout.propTypes = {
  getWorkout: PropTypes.func.isRequired,
  updateWorkout: PropTypes.func.isRequired,
  deleteWorkout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  workout: state.workouts.workout,
});

export default connect(mapStateToProps, { getWorkout, updateWorkout, deleteWorkout })(Workout);
