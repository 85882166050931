/*eslint-disable*/
import React, { useEffect } from 'react';
import {humanizeDuration} from '../../utils/utils';

// reactstrap components
import { Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import WorkoutsTable from './WorkoutsTable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getProfiles } from '../../actions/profiles';

// core components
const SectionAnalytics = ({ profiles, getProfiles }) => {
  useEffect(() => {
    if (!profiles.length) {
      getProfiles();
    }
  }, [profiles]);
  const [activePill, setActivePill] = React.useState('1');
  return (
    <>
      <div className="section section-project cd-section">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title">Summary</h2>
            </Col>
            <div className="m-auto">
              <Nav className="nav-pills-danger" pills>
                <NavItem>
                  <NavLink
                    className={activePill === '1' ? 'active' : ''}
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      setActivePill('1');
                    }}
                  >
                    Weekly
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activePill === '2' ? 'active' : ''}
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      setActivePill('2');
                    }}
                  >
                    Monthly
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activePill === '3' ? 'active' : ''}
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      setActivePill('3');
                    }}
                  >
                    Yearly
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activePill === '4' ? 'active' : ''}
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      setActivePill('4');
                    }}
                  >
                    All Time
                  </NavLink>
                </NavItem>
              </Nav>
              {/* Pill panes */}
              <TabContent activeTab={'pill-' + activePill}>
                <TabPane tabId={'pill-' + activePill} />
                <TabPane tabId={'pill-' + activePill} />
                <TabPane tabId={'pill-' + activePill} />
                <TabPane tabId={'pill-' + activePill} />
              </TabContent>
            </div>
          </Row>
          <Row>
            <WorkoutsTable />
          </Row>
          {/*
          TODO: hack until we know how to manage profiles and analytical info better
          TODO: get only one profile :P instead of all of them.
           */}
          {profiles && profiles[0] ? (
            <Row>
              <Col lg={12}>
                >
                <div className="description-section text-center">
                  <ul
                    className="list-unstyled"
                    style={{
                      maxWidth: 300,
                      margin: 'auto',
                    }}
                  >
                    <li className="d-flex justify-content-between pl-3 pr-3">
                      <span className="font-weight-bold">Total workouts:</span>{' '}
                      {profiles[0].workout_count}
                    </li>
                    <li className="d-flex justify-content-between pl-3 pr-3">
                      <span className="font-weight-bold"># of sets:</span> {profiles[0].set_count}
                    </li>
                    <li className="d-flex justify-content-between pl-3 pr-3">
                      <span className="font-weight-bold"># of reps:</span> {profiles[0].rep_count}
                    </li>

                    <li className="d-flex justify-content-between pl-3 pr-3">
                      <span className="font-weight-bold">Total duration</span>{' '}
                      {humanizeDuration(profiles[0].duration)}
                    </li>

                    <li className="d-flex justify-content-between pl-3 pr-3">
                      <span className="font-weight-bold">Avg set reps</span>{' '}
                      {profiles[0].average_set_reps}
                    </li>

                    <li className="d-flex justify-content-between pl-3 pr-3">
                      <span className="font-weight-bold">Avg set duration</span>{' '}
                      {humanizeDuration(profiles[0].average_set_duration)}
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          ) : (
            false
          )}
        </Container>
      </div>
    </>
  );
};

SectionAnalytics.propTypes = {
  getProfiles: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profiles: state.profiles.profiles,
});

export default connect(mapStateToProps, { getProfiles })(SectionAnalytics);
