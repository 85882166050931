import React from 'react';

// reactstrap components

// core components
import WorkoutPageHeader from '../layout/WorkoutPageHeader';
import Workout from '../old/Workout';
import FooterWhite from '../layout/FooterWhite';

const WorkoutPage = ({match}) => {
  document.documentElement.classList.remove('nav-open');
  React.useEffect(() => {
    document.body.classList.add('product-page');
    return function cleanup() {
      document.body.classList.remove('product-page');
    };
  });
  return (
    <>
      <WorkoutPageHeader/>
      <div className="main">
        <div className="section">
          <Workout match={match}/>>
        </div>
      </div>
      <FooterWhite/>
    </>
  );
};

export default WorkoutPage;
