import axios from 'axios';
import {createMessage, returnErrors} from './messages';
import {tokenConfig} from './auth';

import {
  ADD_WORKOUT,
  CLEAR_SETS,
  DELETE_WORKOUT,
  GET_SETS,
  GET_WORKOUT,
  GET_WORKOUTS,
  UPDATE_WORKOUT
} from './types';


// GET WORKOUTS
export const getWorkouts = () => (dispatch, getState) => {
  axios
    .get('/api/workouts/', tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_WORKOUTS,
        payload: res.data,
      });
    })
    .catch((err) => dispatch(returnErrors(err)));
};

// GET WORKOUTS
export const getWorkout = (id) => (dispatch, getState) => {
  axios
    .get(`/api/workouts/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_WORKOUT,
        payload: res.data,
      });
      dispatch({
        type: GET_SETS,
        payload: res.data.sets,
      })
    })
    .catch((err) => dispatch(returnErrors(err)));
};

// UPDATE WORKOUT
export const updateWorkout = (workout) => (dispatch, getState) => {
  axios
    .put(`/api/workouts/${workout.id}/`, workout, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_WORKOUT,
        payload: res.data,
      });
    })
    .catch((err) => dispatch(returnErrors(err)));
};

// DELETE WORKOUT
export const deleteWorkout = (id) => (dispatch, getState) => {
  axios
    .delete(`/api/workouts/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({deleteWorkout: 'Workout Deleted'}));
      dispatch({
        type: DELETE_WORKOUT,
        payload: id,
      });
      dispatch({
        type: CLEAR_SETS
      })
    })
    .catch((err) => console.log(err));
};

// ADD WORKOUT
export const addWorkout = (workout) => (dispatch, getState) => {
  axios
    .post('/api/workouts/', workout, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: ADD_WORKOUT,
        payload: res.data,
      });
      dispatch({
        type: GET_SETS,
        payload: res.data.sets
      })
      dispatch(createMessage({addWorkout: 'Workout Added'}));

    })
    .catch((err) => {
      if (!err.response) {
        console.error(err.response)
      }
      dispatch(returnErrors(err))

    });
};
