import humanize from 'humanize-duration';

export const humanizeDuration = (seconds, options = {round: true, language: 'shortEn',languages: {
    shortEn: {
      y: () => "y",
      mo: () => "mo",
      w: () => "w",
      d: () => "d",
      h: () => "h",
      m: () => "m",
      s: () => "s",
      ms: () => "ms",
    },
  },}) => {
  return humanize(seconds * 1000, options);
};

export const test = () => {};
