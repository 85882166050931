import { CREATE_MESSAGE, GET_ERRORS, CLEAR_MESSAGES } from './types';

// CREATE MESSAGE
export const createMessage = (msg) => {
  return {
    type: CREATE_MESSAGE,
    payload: msg,
  };
};

// CLEAR MESSAGES
export const clearMessages = () => {
  return {
    type: CLEAR_MESSAGES,
    payload: {},
  };
};


// RETURN ERRORS
export const returnErrors = (err = {}) => {
  let msg, status;
  if (err.response){
    msg = err.response.data
    status = err.response.status
  } else {
    msg = JSON.stringify(err)
  }
  return {
    type: GET_ERRORS,
    payload: { msg, status },
  };
};
