import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getWorkouts, deleteWorkout } from '../../actions/workouts';

// eslint-disable-next-line no-shadow
const Workouts = ({ workouts, getWorkouts, deleteWorkout }) => {
  useEffect(() => {
    if (!workouts.length){
      getWorkouts();
    }
  }, [workouts]);

  return (
    <>
      <h2>Workouts</h2>
      <table className="table table-striped table-sm">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Active</th>
            <th># of Sets</th>
            <th>User</th>
            <th>Created</th>
            <th>Last Update</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {workouts &&
            workouts.map((workout) => (
              <tr key={workout.id}>
                <td>{workout.id}</td>
                <td>{workout.name}</td>
                <td>{workout.active ? <input type="checkbox" checked readOnly /> : false}</td>
                <td>{workout.sets.length}</td>
                <td>{workout.username}</td>
                <td>{workout.created_date}</td>
                <td>{workout.modified_date}</td>
                <td>
                  <button
                    onClick={deleteWorkout.bind(this, workout.id)}
                    className="btn btn-danger btn-sm"
                  >
                    {' '}
                    Delete
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

Workouts.propTypes = {
  getWorkouts: PropTypes.func.isRequired,
  deleteWorkout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  workouts: state.workouts.workouts,
});

export default connect(mapStateToProps, { getWorkouts, deleteWorkout })(Workouts);
