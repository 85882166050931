import React, { useEffect, useState } from 'react';
import Datetime from 'react-datetime';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import LoadingMask from '../layout/LoadingMask';

import { getWorkout, updateWorkout } from '../../actions/workouts';

const EditWorkout = ({
  updateWorkout,
  workout: propsWorkout,
  completeWorkout = false,
  iconOnly = false,
  fetchWorkoutID,
  getWorkout,
}) => {
  const initialWorkoutState = {
    id: null,
    name: '',
    created_date: null,
    active: false,
  };

  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [workout, setWorkout] = useState(initialWorkoutState);

  useEffect(() => {
    if (!modal) {
      return;
    }
    if (!fetchWorkoutID) {
      setWorkout(propsWorkout);
    }
    if (fetchWorkoutID && propsWorkout && propsWorkout.id !== fetchWorkoutID) {
      getWorkout(fetchWorkoutID);
      setIsLoading(true);
    } else {
      setWorkout(propsWorkout);
      setIsLoading(false);
    }
  }, [fetchWorkoutID, modal, propsWorkout]);

  useEffect(() => {
    if (!isLoading) {
      const reps = document.getElementById('reps');
      if (reps) {
        reps.focus();
      }
    }
  }, [isLoading]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setWorkout({ ...workout, [name]: value });
  };
  const handleChangeDate = (date) => {
    setWorkout({ ...workout, created_date: date });
  };

  const handleFocus = (event) => event.target.select();

  const toggle = () => setModal(!modal);
  const saveWorkout = () => {
    const data = {
      id: workout.id,
      name: workout.name,
      created_date: workout.created_date,
      active: workout.active,
    };
    if (completeWorkout) {
      data.active = false;
    }
    updateWorkout(data);
    toggle();
  };

  const ToggleButton = ({ completeWorkout, iconOnly }) => {
    if (completeWorkout) {
      return (
        <Button color="success" type="button" onClick={toggle}>
          Complete workout
        </Button>
      );
    } else if (iconOnly) {
      return (
        <Button onClick={toggle} className="btn-just-icon btn-link" color="warning">
          <i className="fa fa-pencil-square-o" />
        </Button>
      );
    }
    return (
      <Button color="warning" type="button" onClick={toggle}>
        Edit Workout
      </Button>
    );
  };

  const headerTitle = completeWorkout ? 'Complete Workout' : 'Edit Workout';

  const PrimaryAction = () => (
    <Button color="success" type="button" onClick={saveWorkout}>
      Save
    </Button>
  );

  const SecondaryAction = () => (
    <Button color="default" type="button" onClick={toggle}>
      Cancel
    </Button>
  );

  return (
    <div>
      <ToggleButton completeWorkout={completeWorkout} iconOnly={iconOnly} />
      <Modal isOpen={modal} toggle={toggle} className="">
        <LoadingMask loading={isLoading} />
        <ModalHeader toggle={toggle} tag="span">
          <span className="text-center h5 font-weight-bold">{headerTitle}</span>
        </ModalHeader>
        <ModalBody className="p-4">
          <Form>
            <FormGroup>
              <Label htmlFor="name">Workout Name</Label>
              <Input
                type="text"
                className="form-control"
                id="name"
                onFocus={handleFocus}
                value={workout.name}
                onChange={handleInputChange}
                name="name"
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="created_date">Date</Label>
              <InputGroup className="date">
                <Datetime
                  onChange={handleChangeDate}
                  value={moment(workout.created_date)}
                  id="created_date"
                  name="created_date"
                  inputProps={{
                    className: 'form-control',
                    placeholder: 'Date',
                  }}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <span className="glyphicon glyphicon-calendar">
                      <i className="fa fa-calendar" />
                    </span>
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
            {!completeWorkout ? (
              <FormGroup check>
                <Label check>
                  <Input checked={workout.active} onChange={handleInputChange} type="checkbox" />
                  Active
                  <span className="form-check-sign" />
                </Label>
              </FormGroup>
            ) : (
              false
            )}
          </Form>
        </ModalBody>
        <ModalFooter className="p-4">
          <div className="left-side">
            <PrimaryAction />
          </div>
          <div className="right-side">
            <SecondaryAction />
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

EditWorkout.propTypes = {
  updateWorkout: PropTypes.func.isRequired,
  getWorkout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  workout: state.workouts.workout,
});

export default connect(mapStateToProps, { updateWorkout, getWorkout })(EditWorkout);
