import axios from 'axios';
import { createMessage, returnErrors } from './messages';
import { tokenConfig } from './auth';

import {
  ADD_PROFILE,
  CLEAR_SETS,
  DELETE_PROFILE,
  GET_SETS,
  GET_PROFILE,
  GET_PROFILES,
  UPDATE_PROFILE,
} from './types';

// GET PROFILES
export const getProfiles = () => (dispatch, getState) => {
  axios
    .get('/api/profiles/', tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_PROFILES,
        payload: res.data,
      });
    })
    .catch((err) => dispatch(returnErrors(err)));
};

// GET PROFILES
export const getProfile = (id) => (dispatch, getState) => {
  axios
    .get(`/api/profiles/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_PROFILE,
        payload: res.data,
      });
      dispatch({
        type: GET_SETS,
        payload: res.data.sets,
      });
    })
    .catch((err) => dispatch(returnErrors(err)));
};

// UPDATE PROFILE
export const updateProfile = (profile) => (dispatch, getState) => {
  axios
    .put(`/api/profiles/${profile.id}/`, profile, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_PROFILE,
        payload: res.data,
      });
    })
    .catch((err) => dispatch(returnErrors(err)));
};

// DELETE PROFILE
export const deleteProfile = (id) => (dispatch, getState) => {
  axios
    .delete(`/api/profiles/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ deleteProfile: 'Profile Deleted' }));
      dispatch({
        type: DELETE_PROFILE,
        payload: id,
      });
      dispatch({
        type: CLEAR_SETS,
      });
    })
    .catch((err) => console.log(err));
};

// ADD PROFILE
export const addProfile = (profile) => (dispatch, getState) => {
  axios
    .post('/api/profiles/', profile, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: ADD_PROFILE,
        payload: res.data,
      });
      dispatch({
        type: GET_SETS,
        payload: res.data.sets,
      });
      dispatch(createMessage({ addProfile: 'Profile Added' }));
    })
    .catch((err) => {
      if (!err.response) {
        console.error(err.response);
      }
      dispatch(returnErrors(err));
    });
};
