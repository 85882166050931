/*eslint-disable*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";
import Workouts from "./Workouts";

// core components

function SectionAnalytics() {
  const [activePill, setActivePill] = React.useState("1");
  return (
    <>
      <div className="section section-project cd-section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Summary</h2>
              </Col>
              <div className="m-auto">
                <Nav className="nav-pills-danger" pills>
                  <NavItem>
                    <NavLink
                      className={activePill === "1" ? "active" : ""}
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setActivePill("1");
                      }}
                    >
                      Weekly
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activePill === "2" ? "active" : ""}
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setActivePill("2");
                      }}
                    >
                      Monthly
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activePill === "3" ? "active" : ""}
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setActivePill("3");
                      }}
                    >
                      Yearly
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activePill === "4" ? "active" : ""}
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setActivePill("4");
                      }}
                    >
                      All Time
                    </NavLink>
                  </NavItem>
                </Nav>
                {/* Pill panes */}
                <TabContent activeTab={"pill-" + activePill}>
                  <TabPane tabId={"pill-" + activePill} />
                  <TabPane tabId={"pill-" + activePill} />
                  <TabPane tabId={"pill-" + activePill} />
                  <TabPane tabId={"pill-" + activePill} />
                </TabContent>
              </div>
            </Row>
            <div className="section">
              <Container>
                <Row>
                  <Workouts/>
                </Row>
              </Container>
            </div>
            {/*<Row>*/}
            {/*  <Col md="5">*/}
            {/*    <Card*/}
            {/*      data-background="image"*/}
            {/*      style={{*/}
            {/*        backgroundImage:*/}
            {/*          "url(" +*/}
            {/*          require("assets/img/sections/pavel-kosov.jpg") +*/}
            {/*          ")",*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      <CardBody>*/}
            {/*        <h6 className="card-category">Productivy Apps</h6>*/}
            {/*        <a href="#pablo" onClick={(e) => e.preventDefault()}>*/}
            {/*          <CardTitle tag="h3">The Best Productivity Apps</CardTitle>*/}
            {/*        </a>*/}
            {/*        <p className="card-description">*/}
            {/*          Don't be scared of the truth because we need to restart*/}
            {/*          the human foundation in truth And I love you like Kanye*/}
            {/*          loves Kanye I love Rick Owens’ bed design but the back*/}
            {/*          is...*/}
            {/*        </p>*/}
            {/*        <br />*/}
            {/*        <Button*/}
            {/*          className="btn-round"*/}
            {/*          color="danger"*/}
            {/*          href="#pablo"*/}
            {/*          onClick={(e) => e.preventDefault()}*/}
            {/*        >*/}
            {/*          <i className="fa fa-book mr-1" />*/}
            {/*          Read Article*/}
            {/*        </Button>*/}
            {/*      </CardBody>*/}
            {/*    </Card>*/}
            {/*  </Col>*/}
            {/*  <Col md="7">*/}
            {/*    <Card*/}
            {/*      data-background="image"*/}
            {/*      style={{*/}
            {/*        backgroundImage:*/}
            {/*          "url(" +*/}
            {/*          require("assets/img/sections/gukhwa-jang.jpg") +*/}
            {/*          ")",*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      <CardBody>*/}
            {/*        <h6 className="card-category">Materials</h6>*/}
            {/*        <CardTitle tag="h3">*/}
            {/*          US venture investment ticks up*/}
            {/*        </CardTitle>*/}
            {/*        <p className="card-description">*/}
            {/*          Venture investment in U.S. startups rose sequentially in*/}
            {/*          the second quarter of 2020, boosted by large, late-stage*/}
            {/*          financings and a few outsized early-stage rounds....*/}
            {/*        </p>*/}
            {/*        <br />*/}
            {/*        <Button*/}
            {/*          className="btn-round"*/}
            {/*          color="primary"*/}
            {/*          href="#pablo"*/}
            {/*          onClick={(e) => e.preventDefault()}*/}
            {/*        >*/}
            {/*          <i className="fa fa-book mr-1" />*/}
            {/*          Read Article*/}
            {/*        </Button>*/}
            {/*      </CardBody>*/}
            {/*    </Card>*/}
            {/*  </Col>*/}
            {/*</Row>*/}
            {/*<Row>*/}
            {/*  <Col md="7">*/}
            {/*    <Card*/}
            {/*      data-background="image"*/}
            {/*      style={{*/}
            {/*        backgroundImage:*/}
            {/*          "url(" +*/}
            {/*          require("assets/img/sections/joshua-stannard.jpg") +*/}
            {/*          ")",*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      <CardBody>*/}
            {/*        <h6 className="card-category">Productivy Apps</h6>*/}
            {/*        <a href="#pablo" onClick={(e) => e.preventDefault()}>*/}
            {/*          <CardTitle tag="h3">MateLabs mixes machine</CardTitle>*/}
            {/*        </a>*/}
            {/*        <p className="card-description">*/}
            {/*          If you’re not familiar with IFTTT, it’s an automation tool*/}
            {/*          for creating your own if/then statements without any*/}
            {/*          programming knowledge. The service makes it possible...*/}
            {/*        </p>*/}
            {/*        <br />*/}
            {/*        <Button*/}
            {/*          className="btn-round"*/}
            {/*          color="info"*/}
            {/*          href="#pablo"*/}
            {/*          onClick={(e) => e.preventDefault()}*/}
            {/*        >*/}
            {/*          <i className="fa fa-book mr-1" />*/}
            {/*          Read Article*/}
            {/*        </Button>*/}
            {/*      </CardBody>*/}
            {/*    </Card>*/}
            {/*  </Col>*/}
            {/*  <Col md="5">*/}
            {/*    <Card*/}
            {/*      data-background="image"*/}
            {/*      style={{*/}
            {/*        backgroundImage:*/}
            {/*          "url(" +*/}
            {/*          require("assets/img/sections/ilya-yakover.jpg") +*/}
            {/*          ")",*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      <CardBody>*/}
            {/*        <h6 className="card-category">Materials</h6>*/}
            {/*        <CardTitle tag="h3">*/}
            {/*          How to find the ‘right’ contacts.*/}
            {/*        </CardTitle>*/}
            {/*        <p className="card-description">*/}
            {/*          Boom, the invitations start flying and Brella makes it*/}
            {/*          easy to accept/decline, schedule a time and reserve a*/}
            {/*          meeting space in Disrupt’s white-glove CrunchMatch*/}
            {/*          meeting...*/}
            {/*        </p>*/}
            {/*        <br />*/}
            {/*        <Button*/}
            {/*          className="btn-round"*/}
            {/*          color="warning"*/}
            {/*          href="#pablo"*/}
            {/*          onClick={(e) => e.preventDefault()}*/}
            {/*        >*/}
            {/*          <i className="fa fa-book mr-1" />*/}
            {/*          Read Article*/}
            {/*        </Button>*/}
            {/*      </CardBody>*/}
            {/*    </Card>*/}
            {/*  </Col>*/}
            {/*</Row>*/}
          </Container>
        </div>

    </>
  );
}

export default SectionAnalytics;
