import React from 'react';
import { Container } from 'reactstrap';

// reactstrap components

// core components

function WorkoutPageHeader() {
  document.documentElement.classList.remove('nav-open');
  React.useEffect(() => {
    document.body.classList.add('workout-page');
    return function cleanup() {
      document.body.classList.remove('workout-page');
    };
  });
  return (
    <>
      <div
        className="page-header page-header-xs"
        style={{
          backgroundImage: 'url(' + require('assets/img/cfay/home-workout-5089052_1920.jpg') + ')',
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="motto">
              <h1 className="title font-weight-bold">WORKOUT</h1>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default WorkoutPageHeader;
