import React, { useEffect, useRef, useState } from 'react';

import { Link, Redirect, useHistory } from 'react-router-dom';

// reactstrap components
import { Button, Container } from 'reactstrap';
import { addWorkout, getWorkouts } from '../../actions/workouts';
import { clearMessages } from '../../actions/messages';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// core components

function usePrevious(workout) {
  const prevWorkoutRef = useRef();
  useEffect(() => {
    prevWorkoutRef.current = (workout && workout.id) || null;
  }, [workout]);
  return prevWorkoutRef.current;
}

const DashboardHeader = ({
  workouts,
  workout,
  messages,
  addWorkout,
  clearMessages,
  getWorkouts,
}) => {
  const history = useHistory();
  let pageHeader = React.createRef();
  const [activeWorkout, setActiveWorkout] = useState(null);
  const prevWorkoutID = usePrevious(workout);
  if ((workout && workout.id) !== prevWorkoutID) {
    if (workout && workout.id) {
      <Redirect to={`/workout/${workout.id}`} />;
    }
  }
  useEffect(() => {
    if (messages.addWorkout && messages.addWorkout === 'Workout Added') {
      getWorkouts();
      clearMessages();
      history.push(`/workout/${workout.id}`);
    }
  }, [messages]);
  useEffect(() => {
    const workout = workouts.find((workout) => workout.active === true);
    if (workout) {
      setActiveWorkout(workout);
    }
  }, [workouts]);

  useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        if (pageHeader.current && pageHeader.current.style) {
          let windowScrollTop = window.pageYOffset / 3;
          pageHeader.current.style.transform = 'translate3d(0,' + windowScrollTop + 'px,0)';
        }
      };
      window.addEventListener('scroll', updateScroll);
      return function cleanup() {
        window.removeEventListener('scroll', updateScroll);
      };
    }
  });

  return (
    <>
      <div
        className="page-header"
        ref={pageHeader}
        style={{
          backgroundImage: 'url(' + require('assets/img/cfay/street-workout-2682498.jpg') + ')',
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="motto">
              <h1 className="title">Begin your workout</h1>
              <br />

              <Button
                className="btn-round mr-1"
                color="success"
                onClick={() => {
                  addWorkout();
                }}
              >
                <i className="fa fa-play" />
                Start Now
              </Button>
              {activeWorkout ? (
                <Link to={`/workout/${activeWorkout.id}`}>
                  <Button className="btn-round mr-1" color="neutral">
                    <i className="fa fa-play" />
                    Resume workout
                  </Button>{' '}
                </Link>
              ) : (
                false
              )}
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};
DashboardHeader.propTypes = {
  addWorkout: PropTypes.func.isRequired,
  clearMessages: PropTypes.func.isRequired,
  getWorkouts: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  workouts: state.workouts.workouts,
  workout: state.workouts.workout,
  messages: state.messages,
});

export default connect(mapStateToProps, { addWorkout, clearMessages, getWorkouts })(
  DashboardHeader,
);
