import {
  ADD_WORKOUT,
  CLEAR_WORKOUTS,
  DELETE_WORKOUT,
  GET_WORKOUT,
  GET_WORKOUTS,
  UPDATE_WORKOUT,
} from '../actions/types.js';

const initialState = {
  workouts: [],
  workout: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_WORKOUT:
      return {
        ...state,
        workouts: [...state.workouts, action.payload],
        workout: action.payload,
      };
    case CLEAR_WORKOUTS:
      return {
        ...state,
        workouts: [],
      };
    case DELETE_WORKOUT:
      return {
        ...state,
        workouts: state.workouts.filter((workout) => workout.id !== action.payload),
      };

    case GET_WORKOUT:
      return {
        ...state,
        workout: action.payload,
      };
    case GET_WORKOUTS:
      return {
        ...state,
        workouts: action.payload,
      };
    case UPDATE_WORKOUT:
      return {
        ...state,
        workout: action.payload,
      };

    default:
      return state;
  }
}
