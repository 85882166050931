import React, { useMemo } from 'react';
import ReactTable from '../layout/ReactTable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteSet, getSets } from '../../actions/sets';
import { getWorkout } from '../../actions/workouts';
import { clearMessages } from '../../actions/messages';
import { Button } from 'reactstrap';
import { humanizeDuration } from '../../utils/utils';

import EditSetModal from './EditSetModal';

const SetsTable = ({ data, deleteSet }) => {
  const columns = useMemo(() => [
    {
      Header: () => null,
      id: 'index',
      Cell: ({ row, data }) => <span>{data.length - row.index}</span>,
    },
    {
      Header: 'Exercise',
      accessor: 'exercise_name',
    },
    {
      Header: 'Reps',
      accessor: 'reps',
    },
    {
      Header: 'duration',
      Cell: ({ row }) => <span>{humanizeDuration(row.original.duration)}</span>,
    },
    {
      Header: () => null,
      id: 'actions',
      Cell: ({ row }) => {
        return (
          <>
            <EditSetModal set={row.original} />
            <Button
              onClick={deleteSet.bind(this, row.original)}
              className="btn-just-icon btn-link"
              color="danger"
            >
              <i className="fa fa-trash-o" />
            </Button>
          </>
        );
      },
    },
  ]);
  return <ReactTable className="tableFixHead table-borderless" data={data} columns={columns} />;
};

SetsTable.propTypes = {
  getSets: PropTypes.func.isRequired,
  deleteSet: PropTypes.func.isRequired,
  clearMessages: PropTypes.func.isRequired,
  getWorkout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  sets: state.sets.sets,
  messages: state.messages,
});

export default connect(mapStateToProps, {
  getSets,
  deleteSet,
  getWorkout,
  clearMessages,
})(SetsTable);
