import { combineReducers } from 'redux';
import exercises from './exercises';
import workouts from './workouts';
import sets from './sets';
import errors from './errors';
import messages from './messages';
import auth from './auth';

// const redirect = (state = {}, action) => {
//   switch (action.type) {
//     case REDIRECT:
//       return { redirectTo: action.payload };
//     default:
//       return state;
//   }
// };


export default combineReducers({
  exercises,
  workouts,
  sets,
  errors,
  messages,
  auth,
});
