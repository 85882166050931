import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { deleteSet, getSets } from '../../actions/sets';
import AddSetModal from './AddSetModal';
import EditWorkoutModal from './EditWorkoutModal';
import { getWorkout } from '../../actions/workouts';
import { clearMessages } from '../../actions/messages';
import SetsTable from './SetsTable';
import { Col, Container, Row } from 'reactstrap';
import { humanizeDuration } from '../../utils/utils';

// eslint-disable-next-line no-shadow
const Sets = ({
  workoutID,
  workoutActive,
  sets = [],
  messages,
  getWorkout,
  deleteSet,
  clearMessages,
  currentWorkout,
  workout,
}) => {
  useEffect(() => {
    if (messages.addSet || messages.deleteSet) {
      getWorkout(workoutID);
      clearMessages();
    }
  }, [messages]);

  return (
    <>
      <div
        className="pricing-4 section"
        style={{
          position: 'relative',
          overflow: 'hidden',
          padding: 16,
        }}
      >
        <Container
          style={{
            position: 'relative',
            zIndex: 2,
          }}
        >
          <Row>
            <Col md="4">
              <div className="info">
                <div className="description-section">
                  <h4 className="info-title">Workout Summary</h4>
                  {workoutID && workoutActive && <AddSetModal />}
                  <ul className="list-unstyled">
                    <li className="d-flex justify-content-between pl-3 pr-3">
                      <span className="font-weight-bold"># of sets:</span>{' '}
                      {workout.workout_set_count}
                    </li>

                    <li className="d-flex justify-content-between pl-3 pr-3">
                      <span className="font-weight-bold"># of reps:</span>{' '}
                      {workout.workout_rep_count}
                    </li>

                    <li className="d-flex justify-content-between pl-3 pr-3">
                      <span className="font-weight-bold">Total duration</span>{' '}
                      {humanizeDuration(workout.workout_duration)}
                    </li>

                    <li className="d-flex justify-content-between pl-3 pr-3">
                      <span className="font-weight-bold">Avg set reps</span>{' '}
                      {workout.workout_average_set_reps}
                    </li>

                    <li className="d-flex justify-content-between pl-3 pr-3">
                      <span className="font-weight-bold">Avg set duration</span>{' '}
                      {humanizeDuration(workout.workout_average_set_duration)}
                    </li>

                    <li className="d-flex justify-content-between pl-3 pr-3">
                      <span className="font-weight-bold">Exercise Families:</span>{' '}
                      {workout.exercise_families}
                    </li>
                    <li className="d-flex justify-content-between pl-3 pr-3">
                      <span className="font-weight-bold">Exercise Categories:</span>{' '}
                      {workout.exercise_categories}
                    </li>
                    <li className="d-flex justify-content-between pl-3 pr-3">
                      <span className="font-weight-bold">Exercise Difficulties:</span>{' '}
                      {workout.exercise_difficulties}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="info">
                <div className="description">
                  <EditWorkoutModal completeWorkout={workoutActive} />
                </div>
              </div>
            </Col>
            <Col md="8">
              <div className="info" style={{ width: 'fit-content', maxWidth: 'none' }}>
                <div className="description-section">
                  <SetsTable data={sets} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

Sets.propTypes = {
  getSets: PropTypes.func.isRequired,
  deleteSet: PropTypes.func.isRequired,
  clearMessages: PropTypes.func.isRequired,
  getWorkout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  sets: state.sets.sets,
  workout: state.workouts.workout,
  messages: state.messages,
});

export default connect(mapStateToProps, { getSets, deleteSet, getWorkout, clearMessages })(Sets);
