import {ADD_SET, CLEAR_SETS, DELETE_SET, GET_SET, GET_SETS, UPDATE_SET} from '../actions/types.js';

const initialState = {
  sets: [],
  set: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_SET:
      return {
        ...state,
        sets: [...state.sets, action.payload],
        set: action.payload,
      };

    case CLEAR_SETS:
      return {
        ...state,
        sets: [],
      };
    case DELETE_SET:
      return {
        ...state,
        sets: state.sets.filter((set) => set.id !== action.payload),
      };

    case GET_SET:
      return {
        ...state,
        set: action.payload,
      };
    case GET_SETS:
      return {
        ...state,
        sets: action.payload,
      };

    case UPDATE_SET:
      const index = state.sets.findIndex((set) => set.id !== action.payload)
      let sets = [...state.sets]
      sets[index] = action.payload;

      return {
        ...state,
        set: action.payload,
        sets
      };
    default:
      return state;
  }
}
