import React from "react";
import { Link, Redirect } from 'react-router-dom';

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import LandingPageHeader from "../layout/LandingPageHeader";
import FooterWhite from "../layout/FooterWhite";
import PropTypes from "prop-types";
import {connect} from "react-redux";


const LandingPage = ({ isAuthenticated = false }) => {
  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <LandingPageHeader />
      <div className="wrapper">
        <div className="section text-center landing-section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title">Fitness Progression tracking made easy</h2>
                <h5>
                  It has never been simpler to track your workouts and challenge your friends. One
                  all inclusive app offering recording and analytics in the same place. Bring your
                  training to the next level by making use of the variety of features to help you
                  become more aware of your progression over time.
                </h5>
                <br />
                <Link to="/login">
                <Button
                  className="btn-fill btn-round"
                  color="danger"
                  onClick={(e) => e.preventDefault()}
                >
                  See Details
                </Button>
                </Link>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-danger">
                    <i className="nc-icon nc-bullet-list-67" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Intuitive Tracking</h4>
                    <p className="description">
                      Save time recording your exercises with our seamless tracking flow allowing
                      you to focus on the workout.
                    </p>
                    <Button
                      className="btn-link"
                      color="danger"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      See more
                    </Button>

                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-danger">
                    <i className="nc-icon nc-chart-bar-32" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Insightful Analytics</h4>
                    <p>
                      Informative visualization produced from the workouts you record.
                    </p>
                    <Button
                      className="btn-link"
                      color="danger"
                      href="/"
                      onClick={(e) => e.preventDefault()}
                    >
                      See more
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-danger">
                    <i className="nc-icon nc-sun-fog-29" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Delightful design</h4>
                    <p>
                      Find an experience designed with the user in mind. Our user experience
                      specialists have dedicated countless hours to optimizing your convenience.
                    </p>
                    <Button
                      className="btn-link"
                      color="danger"
                      href="/"
                      onClick={(e) => e.preventDefault()}
                    >
                      See more
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <FooterWhite />
    </>
  );
}
LandingPage.propTypes = {

  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {})(LandingPage);
