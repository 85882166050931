import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import { Provider } from 'react-redux';
import moment from 'moment';

// styles
import '../assets/css/bootstrap.min.css';
import '../assets/scss/paper-kit.scss';
import "../assets/css/bootstrap.override.css";
import '../assets/demo/demo.css';
import '../assets/demo/react-demo.css';

import Dashboard from './pages/Dashboard';
import Alerts from './layout/Alerts';
import Login from './accounts/Login';
import Register from './accounts/Register';
import PrivateRoute from './common/PrivateRoute';

import store from '../store';
import { loadUser } from '../actions/auth';
import LandingPage from './pages/LandingPage';
import MultiDropdownNavbar from './layout/MultiDropdownNavbar';
import WorkoutPage from './pages/WorkoutPage';

moment.defaultFormat = 'DD/MM/YYYY HH:mm a';


// Alert Options
const alertOptions = {
  timeout: 3000,
  position: 'top center',
  containerStyle: { zIndex: 1100 },
};

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  });
  return (
    <Provider store={store}>
      <AlertProvider template={AlertTemplate} {...alertOptions}>
        <Router>
          <>
            <MultiDropdownNavbar />
            <Alerts />
            <Switch>
              <PrivateRoute path="/workout/:id" component={WorkoutPage} />
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <Route exact path="/register" component={Register} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/" component={LandingPage} />

              <Redirect to="/" />
            </Switch>
          </>
        </Router>
      </AlertProvider>
    </Provider>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
