import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../../actions/auth';

// reactstrap components
import { Card, Container, Row, Col } from 'reactstrap';

const Login = (props) => {
  const { isAuthenticated = false, location, login } = props;
  if (isAuthenticated) {
    const path = (location.state && location.state.referrer) || '/dashboard';
    return <Redirect to={path} />;
  }
  const initialState = {
    username: '',
    password: '',
  };
  const [state, setState] = useState(initialState);
  const onSubmit = (e) => {
    e.preventDefault();
    login(state.username, state.password);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  return (
    <div className="wrapper">
      <div
        className="page-header"
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          backgroundImage: 'url(' + require('assets/img/cfay/plank-2054729.jpg') + ')',
        }}
      >
        <div className="filter" />
        <Container style={{ marginTop: -200 }}>
          <Row className="m-auto">
            <Col className="ml-auto mr-auto" lg="4" md="6" sm="6">
              <Card className="p-5">
                <h2 className="text-center">Login</h2>
                <form onSubmit={onSubmit}>
                  <div className="form-group">
                    <label htmlFor="username">Username</label>
                    <input
                      type="text"
                      className="form-control"
                      name="username"
                      id="username"
                      onChange={handleInputChange}
                      value={state.username}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      id="password"
                      onChange={handleInputChange}
                      value={state.password}
                    />
                  </div>

                  <div className="form-group text-center">
                    <button type="submit" className="btn btn-success">
                      Login
                    </button>
                  </div>
                  <p>
                    Don't have an account? <Link to="/register">Register</Link>
                  </p>
                </form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(Login);
