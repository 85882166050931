import {
  ADD_PROFILE,
  CLEAR_PROFILES,
  DELETE_PROFILE,
  GET_PROFILE,
  GET_PROFILES,
  UPDATE_PROFILE,
} from '../actions/types.js';

const initialState = {
  profiles: [],
  profile: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_PROFILE:
      return {
        ...state,
        profiles: [...state.profiles, action.payload],
        profile: action.payload,
      };
    case CLEAR_PROFILES:
      return {
        ...state,
        profiles: [],
      };
    case DELETE_PROFILE:
      return {
        ...state,
        profiles: state.profiles.filter((profile) => profile.id !== action.payload),
      };

    case GET_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    case GET_PROFILES:
      return {
        ...state,
        profiles: action.payload,
      };
    case UPDATE_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };

    default:
      return state;
  }
}
