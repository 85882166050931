import axios from 'axios';
import { createMessage, returnErrors } from './messages';
import { tokenConfig } from './auth';

import { ADD_EXERCISE, DELETE_EXERCISE, GET_EXERCISES } from './types';

// GET EXERCISES
export const getExercises = () => (dispatch, getState) => {
  axios
    .get('/api/exercises/', tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_EXERCISES,
        payload: res.data,
      });
    })
    .catch((err) => dispatch(returnErrors(err)));
};

// DELETE EXERCISE
export const deleteExercise = (id) => (dispatch, getState) => {
  axios
    .delete(`/api/exercises/${id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ deleteExercise: 'Exercise Deleted' }));
      dispatch({
        type: DELETE_EXERCISE,
        payload: id,
      });
    })
    .catch((err) => console.log(err));
};

// ADD EXERCISE
export const addExercise = (exercise) => (dispatch, getState) => {
  axios
    .post('/api/exercises/', exercise, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addExercise: 'Exercise Added' }));
      dispatch({
        type: ADD_EXERCISE,
        payload: res.data,
      });
    })
    .catch((err) => dispatch(returnErrors(err)));
};
