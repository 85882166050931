import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import LoadingMask from "../layout/LoadingMask";

import {getExercises} from '../../actions/exercises';
import {updateSet} from '../../actions/sets';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

const EditSet = ({updateSet, getExercises, exercises, set: propsSet}) => {
  const initialSetState = propsSet || {
    id: null,
    exercise_id: '',
    reps: 1,
    duration: 0,
  };

  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [set, setSet] = useState(initialSetState);
  const [exerciseOptions, setExerciseOptions] = useState([
    {
      value: initialSetState.exercise_id,
      label: initialSetState.exercise_name
    }
  ])

  useEffect(() => {
    if (!exercises.length && !modal) {
      return
    }
    if (!exercises.length) {
      getExercises();
      setIsLoading(true)
    } else {
      setExerciseOptions(exercises.map(exercise => ({
        value: exercise.id,
        label: exercise.name
      })))
      setIsLoading(false)
    }
  }, [exercises, modal]);


  useEffect(() => {
    if (!isLoading) {
      const reps = document.getElementById('reps');
      if (reps) {
        reps.focus()
      }
    }
  }, [isLoading])


  const handleInputChange = (event) => {
    const {name, value} = event.target;
    setSet({...set, [name]: value});
  };

  const handleChangeExercise = (event) => {
    setSet({...set, exercise_id: event.value});
  };

  const handleFocus = event => event.target.select();

  const toggleLoading = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false)
    }, 300)
  }

  const saveSet = () => {
    const data = {
      id: set.id,
      exercise_id: set.exercise_id,
      reps: set.reps,
      duration: set.duration,
    };
    updateSet(data);
    toggle();
  };

  const toggle = () => setModal(!modal);

  const ToggleButton = () => (
    <Button
      onClick={toggle}
      className="btn-just-icon btn-link"
      color="warning"
    >
      <i className="fa fa-pencil-square-o"/>
    </Button>)

  const headerTitle = 'Edit Set'


  const PrimaryAction = () => (<Button color="success" type="button" onClick={saveSet}>
      Save
    </Button>
  )


  const SecondaryAction = () => (<Button color="default" type="button" onClick={toggle}>
      Cancel
    </Button>
  )

  return (
    <div>
      <ToggleButton/>
      <Modal isOpen={modal} toggle={toggle} className="">
        <LoadingMask loading={isLoading}/>
        <ModalHeader toggle={toggle} tag="span">
          <span className="text-center h5 font-weight-bold">
            {headerTitle}
          </span>
        </ModalHeader>
        <ModalBody className="p-4">
          <Form>
            <FormGroup>
              <Label htmlFor="exercise_id">Exercise</Label>
              <Select options={exerciseOptions}
                      id="exercise_id"
                      defaultValue={exerciseOptions[0]}
                      onChange={handleChangeExercise}
                      name="exercise_id"/>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="reps">Reps</Label>
              <Input
                type="text"
                className="form-control"
                id="reps"
                onFocus={handleFocus}
                value={set.reps}
                onChange={handleInputChange}
                name="reps"
              /></FormGroup>
            <FormGroup>
              <Label htmlFor="duration">Duration</Label>
              <Input
                type="text"
                className="form-control"
                id="duration"
                onFocus={handleFocus}
                value={set.duration}
                onChange={handleInputChange}
                name="duration"
              /></FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter className="p-4">
          <div className="left-side">
            <PrimaryAction/>
          </div>
          <div className="right-side">
            <SecondaryAction/>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

EditSet.propTypes = {
  updateSet: PropTypes.func.isRequired,
  getExercises: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  exercises: state.exercises.exercises,
});

export default connect(mapStateToProps, {updateSet, getExercises})(EditSet);
