import React, { useEffect, useState } from 'react';
import { getExercises } from '../../actions/exercises';
import { addSet } from '../../actions/sets';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const AddSet = ({ handleNewSetAdded, addSet, getExercises, exercises }) => {
  const initialSetState = {
    id: null,
    exercise_id: '',
    reps: 0,
    duration: 0,
  };
  const [set, setSet] = useState(initialSetState);
  const [submitted, setSubmitted] = useState(false);
  useEffect(() => {
    if (!exercises.length) {
      getExercises();
    } else {
      setSet({ ...set, exercise_id: exercises[0].id });
    }
  }, [exercises]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSet({ ...set, [name]: value });
    console.log(set);
  };

  const saveSet = () => {
    const data = {
      exercise_id: set.exercise_id,
      reps: set.reps,
      duration: set.duration,
    };
    addSet(data);
    if (handleNewSetAdded) {
      handleNewSetAdded();
    }
    setSubmitted(true);
  };

  const newSet = () => {
    setSet(initialSetState);
    setSubmitted(false);
  };

  return (
    <div className="col-md-6 mt-5">
      {submitted ? (
        <div>
          <h4>You submitted successfully!</h4>
          <button className="btn btn-success" onClick={newSet}>
            Add
          </button>
        </div>
      ) : (
        <div>
          <div className="form-group">
            <h2>Add a set</h2>
            <label htmlFor="exercise_id">Exercise</label>
            <select
              className="form-control"
              id="exercise_id"
              required
              value={set.exercise_id}
              onChange={handleInputChange}
              name="exercise_id"
            >
              {exercises &&
                exercises.map((exercise, index) => (
                  <option value={exercise.id} key={index}>
                    {exercise.name}
                  </option>
                ))}
            </select>
            <label htmlFor="reps">Reps</label>
            <input
              type="text"
              className="form-control"
              id="reps"
              required
              value={set.reps}
              onChange={handleInputChange}
              name="reps"
            />
            <label htmlFor="duration">Duration</label>
            <input
              type="text"
              className="form-control"
              id="duration"
              required
              value={set.duration}
              onChange={handleInputChange}
              name="duration"
            />
          </div>

          <button onClick={saveSet} className="btn btn-success">
            Create
          </button>
        </div>
      )}
    </div>
  );
};

AddSet.propTypes = {
  addSet: PropTypes.func.isRequired,
  getExercises: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  exercises: state.exercises.exercises,
});

export default connect(mapStateToProps, { addSet, getExercises })(AddSet);
