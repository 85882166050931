import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import LoadingMask from "../layout/LoadingMask";

import {getExercises} from '../../actions/exercises';
import {addSet} from '../../actions/sets';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

const AddSet = ({addSet, getExercises, exercises}) => {
  const initialSetState = {
    id: null,
    exercise_id: '',
    reps: 1,
    duration: 0,
  };

  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [set, setSet] = useState(initialSetState);
  const [addAnother, setAddAnother] = useState(false)
  const [exerciseOptions, setExerciseOptions] = useState([])

  useEffect(() => {
    if (!exercises.length && !modal) {
      return
    }
    if (!exercises.length) {
      getExercises();
      setIsLoading(true)
    } else {
      setExerciseOptions(exercises.map(exercise => ({
        value: exercise.id,
        label: exercise.name
      })))
      setSet({...set, exercise_id: exercises[0].id});
      setIsLoading(false)

    }
  }, [exercises]);
  useEffect(() => {
    if (!isLoading) {
      const reps = document.getElementById('reps');
      if (reps) {
        reps.focus()
      }
    }
  }, [isLoading])

  const handleInputChange = (event) => {
    const {name, value} = event.target;
    setSet({...set, [name]: value});
  };

  const handleChangeExercise = (event) => {
    setSet({...set, exercise_id: event.value});
  };

  const handleFocus = event => event.target.select();

  const toggleLoading = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false)
    }, 300)
  }

  const saveSet = () => {
    const data = {
      exercise_id: set.exercise_id,
      reps: set.reps,
      duration: set.duration,
    };
    addSet(data);
    if (addAnother) {
      toggleLoading();
      return;
    }
    toggle();
  };

  const toggle = () => setModal(!modal);

  const ToggleButton = () => (<Button color="danger" onClick={toggle}>
    Add Set
  </Button>)

  const headerTitle = 'Add Set'


  const PrimaryAction = () => (<Button color="success" type="button" onClick={saveSet}>
      Save
    </Button>
  )


  const SecondaryAction = () => (<Button color="default" type="button" onClick={toggle}>
      Cancel
    </Button>
  )

  return (
    <div>
      <ToggleButton/>
      <Modal isOpen={modal} toggle={toggle} className="">
        <LoadingMask loading={isLoading}/>
        <ModalHeader toggle={toggle} tag="span">
          <span className="text-center h5 font-weight-bold">
            {headerTitle}
          </span>
        </ModalHeader>
        <ModalBody className="p-4">
          <Form>
            <FormGroup>
              <Label htmlFor="exercise_id">Exercise</Label>
              <Select options={exerciseOptions}
                      id="exercise_id"
                      defaultValue={exerciseOptions[0]}
                      onChange={handleChangeExercise}
                      name="exercise_id"/>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="reps">Reps</Label>
              <Input
                type="text"
                className="form-control"
                id="reps"
                onFocus={handleFocus}
                value={set.reps}
                onChange={handleInputChange}
                name="reps"
              /></FormGroup>
            <FormGroup>
              <Label htmlFor="duration">Duration</Label>
              <Input
                type="text"
                className="form-control"
                id="duration"
                onFocus={handleFocus}
                value={set.duration}
                onChange={handleInputChange}
                name="duration"
              /></FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  defaultValue={addAnother}
                  onChange={event => setAddAnother(event.target.value)}
                  type="checkbox"
                />
                Add another <span className="form-check-sign"></span>
              </Label>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter className="p-4">
          <div className="left-side">
            <PrimaryAction/>
          </div>
          <div className="right-side">
            <SecondaryAction/>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

AddSet.propTypes = {
  addSet: PropTypes.func.isRequired,
  getExercises: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  exercises: state.exercises.exercises,
});

export default connect(mapStateToProps, {addSet, getExercises})(AddSet);
