import React, { useEffect, useRef } from 'react';
import { withAlert } from 'react-alert';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

function usePrevious(props) {
  const prevErrorRef = useRef();
  const prevMessageRef = useRef();
  useEffect(() => {
    prevErrorRef.current = props.error;
    prevMessageRef.current = props.message;
  });
  return {
    error: prevErrorRef.current,
    message: prevMessageRef.current,
  };
}

const Alerts = (props) => {
  const { error, alert, message } = props;
  console.log(message);
  const prevProps = usePrevious(props);
  if (error && error !== prevProps.error) {
    if (error.msg.name) alert.error(`Name: ${error.msg.name.join()}`);
    if (error.msg.email) alert.error(`Email: ${error.msg.email.join()}`);
    if (error.msg.message) alert.error(`Message: ${error.msg.message.join()}`);
    if (error.msg.non_field_errors) alert.error(error.msg.non_field_errors.join());
    if (error.msg.username) alert.error(error.msg.username.join());
  }

  if (message && message !== prevProps.message) {
    if (message.deleteWorkout) alert.success(message.deleteWorkout);
    if (message.addWorkout) alert.success(message.addWorkout);
    if (message.addSet) alert.success(message.addSet);
    if (message.deleteSet) alert.success(message.deleteSet);
    if (message.passwordNotMatch) alert.error(message.passwordNotMatch);
  }
  return <></>;
};

Alerts.propTypes = {
  error: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
  alert: PropTypes.object,
};

const mapStateToProps = (state) => ({
  error: state.errors,
  message: state.messages,
});

export default connect(mapStateToProps)(withAlert()(Alerts));
