import React from 'react';

import { Link } from 'react-router-dom';

// reactstrap components
import { Button, Container } from 'reactstrap';

// core components

function LandingPageHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform = 'translate3d(0,' + windowScrollTop + 'px,0)';
      };
      window.addEventListener('scroll', updateScroll);
      return function cleanup() {
        window.removeEventListener('scroll', updateScroll);
      };
    }
  });

  return (
    <>
      <div
        className="page-header"
        ref={pageHeader}
        style={{
          backgroundImage: 'url(' + require('assets/img/cfay/sport-2264825.jpg') + ')',
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="motto">
              <h1 className="title">Begin your workout</h1>
              <h3 className="description">Start tracking your progression today!</h3>
              <br />
              <Link to="/dashboard">
                <Button className="btn-round mr-1" color="neutral">
                  <i className="fa fa-play" />
                  Start Now
                </Button>
              </Link>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default LandingPageHeader;
