import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { register } from '../../actions/auth';
import { createMessage } from '../../actions/messages';
import { Card, Col, Container, Row } from 'reactstrap';

const Register = ({ isAuthenticated = false, register, createMessage }) => {
  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }
  const initialState = {
    username: '',
    email: '',
    password: '',
    password2: '',
  };
  const [state, setState] = useState(initialState);
  const onSubmit = (e) => {
    e.preventDefault();
    const { username, email, password, password2 } = state;
    if (password !== password2) {
      createMessage({ passwordNotMatch: 'Passwords do not match' });
    } else {
      const newUser = {
        username,
        password,
        email,
      };
      register(newUser);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  return (
    <div className="wrapper">
      <div
        className="page-header"
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          backgroundImage: 'url(' + require('assets/img/cfay/plank-2054729.jpg') + ')',
        }}
      >
        <div className="filter" />
        <Container style={{ marginTop: -200 }}>
          <Row className="m-auto">
            <Col className="ml-auto mr-auto" lg="4" md="6" sm="6">
              <Card className="p-5">
                <h2 className="text-center">Register</h2>
                <form onSubmit={onSubmit}>
                  <div className="form-group">
                    <label>Username</label>
                    <input
                      type="text"
                      className="form-control"
                      name="username"
                      onChange={handleInputChange}
                      value={state.username}
                    />
                  </div>
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      onChange={handleInputChange}
                      value={state.email}
                    />
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      onChange={handleInputChange}
                      value={state.password}
                    />
                  </div>
                  <div className="form-group">
                    <label>Confirm Password</label>
                    <input
                      type="password"
                      className="form-control"
                      name="password2"
                      onChange={handleInputChange}
                      value={state.password2}
                    />
                  </div>
                  <div className="form-group text-center">
                    <button type="submit" className="btn btn-success">
                      Register
                    </button>
                  </div>
                  <p>
                    Already have an account? <Link to="/login">Login</Link>
                  </p>
                </form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
Register.propTypes = {
  register: PropTypes.func.isRequired,
  createMessage: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { register, createMessage })(Register);
