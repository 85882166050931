import React from 'react';

import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DashboardHeader from '../layout/DashboardHeader';
import SectionAnalytics from '../workouts/SectionAnalytics';
import FooterWhite from '../layout/FooterWhite';

const Dashboard = ({ isAuthenticated = false }) => {
  if (!isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <DashboardHeader />
      <div className="wrapper">
        <SectionAnalytics />
      </div>
      <FooterWhite />
    </>
  );
};

Dashboard.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {})(Dashboard);
