import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AddSet from './AddSet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getWorkout} from "../../actions/workouts";
import { clearMessages} from "../../actions/messages";

const SetsList = ({ workoutID, workoutActive, sets = [], messages, getWorkout, clearMessages }) => {
  const [currentSet, setCurrentSet] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [searchName, setSearchName] = useState('');
  const [addSetForm, setAddSetForm] = useState(false);


  useEffect(()=>{
    if (messages.addSet) {
    getWorkout(workoutID);
    clearMessages();
  }  }, [messages])

  const onChangeSearchName = (e) => {
    const searchName = e.target.value;
    setSearchName(searchName);
  };

  const setActiveSet = (set, index) => {
    setCurrentSet(set);
    setCurrentIndex(index);
  };


  const handleNewSetAdded = () => {
    getWorkout(workoutID);
    setAddSetForm(false);
  };

  return (
    <div className="row text-left">
      {/*{!workoutID && (*/}
      {/*  <div className="col-md-8">*/}
      {/*    <div className="input-group mb-3">*/}
      {/*      <input*/}
      {/*        type="text"*/}
      {/*        className="form-control"*/}
      {/*        placeholder="Search by name"*/}
      {/*        value={searchName}*/}
      {/*        onChange={onChangeSearchName}*/}
      {/*      />*/}
      {/*      <div className="input-group-append">*/}
      {/*        <button className="btn btn-outline-secondary" type="button" onClick={findByName}>*/}
      {/*          Search*/}
      {/*        </button>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*)}*/}
      <div className="col-md-6">
        <h4 className="text-center">Sets List</h4>
        <h3>Set count: {sets.length}</h3>
        <h3>
          Combined set duration:{' '}
          {sets.reduce((a, b) => {
            return a + b.duration;
          }, 0)}
        </h3>
        <h3>
          Average set duration:{' '}
          {sets.reduce((a, b) => {
            return a + b.duration;
          }, 0) / sets.length}
        </h3>
        <ul className="list-group">
          {sets && sets.length ? (
            sets.map((set, index) => (
              <li
                className={'list-group-item ' + (index === currentIndex ? 'active' : '')}
                onClick={() => setActiveSet(set, index)}
                key={index}
              >
                {set.workout_id ? (
                  <div>
                    <strong>{set.workout_id.name}</strong>
                  </div>
                ) : (
                  false
                )}
                {<div>{set.exercise_id.name}</div>}
                {set.reps ? <div>Reps: {set.reps}</div> : false}
                {set.duration ? <div>Duration: {set.duration}</div> : false}
              </li>
            ))
          ) : (
            <li className="list-group-item">No sets have been created.</li>
          )}
        </ul>
      </div>
      <div className="col-md-6">
        {currentSet ? (
          <div>
            <h4>Current Set</h4>
            <div>
              {currentSet.workout_id ? (
                <div>
                  <strong>{currentSet.workout_id.name}</strong>
                </div>
              ) : (
                false
              )}
              {<div>{currentSet.exercise_id.name}</div>}
              {currentSet.reps ? <div>Reps: {currentSet.reps}</div> : false}
              {currentSet.duration ? <div>Duration: {currentSet.duration}</div> : false}
            </div>

            <Link to={'/sets/' + currentSet.id} className="badge badge-warning">
              Edit
            </Link>
          </div>
        ) : (
          <div>
            <br />
            {sets && sets.length ? <p>Please click on a Set...</p> : false}
          </div>
        )}
      </div>
      {!addSetForm && workoutID && workoutActive && (
        <button
          onClick={() => {
            setAddSetForm(true);
          }}
          className="btn btn-success mt-3"
        >
          Create set
        </button>
      )}
      {addSetForm && (
        <div className="col-md-12">
          <AddSet handleNewSetAdded={handleNewSetAdded} />
        </div>
      )}
    </div>
  );
};

SetsList.propTypes = {
  getWorkout: PropTypes.func.isRequired,
  clearMessages: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  sets: state.workouts.workout.sets,
  messages: state.messages
});

export default connect(mapStateToProps, { getWorkout, clearMessages })(SetsList);
