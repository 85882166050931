import axios from 'axios';
import { createMessage, returnErrors } from './messages';
import { tokenConfig } from './auth';

import { GET_SETS, DELETE_SET, ADD_SET, UPDATE_SET } from './types';

// GET SETS
export const getSets = (workoutID) => (dispatch, getState) => {
  axios
    .get('/api/sets/', tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_SETS,
        payload: res.data,
      });
    })
    .catch((err) => dispatch(returnErrors(err)));
};

// DELETE SET
export const deleteSet = (set) => (dispatch, getState) => {
  axios
    .delete(`/api/sets/${set.id}/`, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ deleteSet: 'Set Deleted' }));
      dispatch({
        type: DELETE_SET,
        payload: set,
      });
    })
    .catch((err) => console.log(err));
};

// ADD SET
export const addSet = (set) => (dispatch, getState) => {
  axios
    .post('/api/sets/', set, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addSet: 'Set Added' }));
      dispatch({
        type: ADD_SET,
        payload: res.data,
      });
    })
    .catch((err) => dispatch(returnErrors(err)));
};

// UPDATE SET
export const updateSet = (set) => (dispatch, getState) => {
  axios
    .patch(`/api/sets/${set.id}/`, set, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_SET,
        payload: res.data,
      });
    })
    .catch((err) => dispatch(returnErrors(err)));
};
