import React, { useEffect, useMemo } from 'react';
import moment from 'moment';
import ReactTable from '../layout/ReactTable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteWorkout, getWorkouts } from '../../actions/workouts';
import { Button, FormGroup, Input, Label } from 'reactstrap';

import EditWorkoutModal from './EditWorkoutModal';
import { Link } from 'react-router-dom';

const WorkoutsTable = ({ deleteWorkout, workouts, getWorkouts }) => {
  useEffect(() => {
    if (!workouts.length) {
      getWorkouts();
    }
  }, [workouts]);

  const columns = useMemo(() => [
    {
      Header: () => null,
      id: 'index',
      Cell: ({ row, data }) => <span>{data.length - row.index}</span>,
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: '# of sets',
      id: 'sets',
      Cell: ({ row }) => <span>{row.original.sets.length}</span>,
    },
    {
      Header: 'Active',
      id: 'active',
      Cell: ({ row }) => (
        <FormGroup check>
          <Label check>
            <Input checked={row.original.active} disabled type="checkbox" />
            <span className="form-check-sign"></span>
          </Label>
        </FormGroup>
      ),
    },
    {
      Header: 'Date',
      id: 'date',
      Cell: ({ row }) => <span>{moment(row.original.created_date).format()}</span>,
    },
    {
      Header: 'Last Update',
      id: 'updated',
      Cell: ({ row }) => <span>{moment(row.original.modified_date).format()}</span>,
    },
    {
      Header: () => null,
      id: 'actions',
      Cell: ({ row }) => {
        return (
          <>
            <EditWorkoutModal fetchWorkoutID={row.original.id} iconOnly />
            <Button
              onClick={deleteWorkout.bind(this, row.original)}
              className="btn-just-icon btn-link"
              color="danger"
            >
              <i className="fa fa-trash-o" />
            </Button>
            <Link to={`/workout/${row.original.id}`}>
              <Button className="btn-just-icon btn-link" color="success">
                <i className="fa fa-play" />
              </Button>
            </Link>
          </>
        );
      },
    },
  ]);
  return (
    <ReactTable className="tableFixHead table-borderless mt-4" data={workouts} columns={columns} />
  );
};

WorkoutsTable.propTypes = {
  getWorkouts: PropTypes.func.isRequired,
  deleteWorkout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  workouts: state.workouts.workouts,
});

export default connect(mapStateToProps, { getWorkouts, deleteWorkout })(WorkoutsTable);
