import React from 'react';
import { Spinner } from 'reactstrap';

function LoadingMask({ loading = true }) {
  let defaultStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    width: loading ? '100%' : 0,
    height: loading ? '100%' : 0,
    zIndex: 10,
    backgroundColor: 'rgb(0, 0, 0)',
    opacity: 0.3,
  };

  return (
    <div className="loading-mask" style={defaultStyle}>
      {loading ? <Spinner /> : null}
    </div>
  );
}

export default LoadingMask;
